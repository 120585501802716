<template>
    <div style="height: 100%;">
        <page-main class="login-form">
            <div style="font-size: 25px;">
                验证码登录
            </div>
            <div style="margin-top: 50px;">
                <el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号" v-model="form.phone"></el-input>
                <el-row style="margin-top: 20px;">
                    <el-col :span="18">
                        <el-input prefix-icon="el-icon-postcard" placeholder="请输入验证码" v-model="form.code"></el-input>
                    </el-col>
                    <el-col :span="6" style="text-align: right">
                        <el-button type="text" @click.stop="sendSMSCode()" :disabled="getCodeIsWaiting">
                            {{ getCodeText }}
                        </el-button>
                    </el-col>
                </el-row>
            </div>
            <div style="margin-top: 50px; text-align: center">
                <el-button type="primary" round style="width: 90%; height: 45px;" @click="doLogin">登录</el-button>
            </div>
            <div style="margin-top: 10px; text-align: center; font-size: 13px; color: #9a9999; margin-bottom: 20px;">
                如果您是第一次登录，将默认同意您注册清单法考系统。
            </div>
        </page-main>
    </div>
</template>

<script>
import {Message} from 'element-ui'
import md5 from 'js-md5';

const _Message = Message

export default {
    name: "login",
    data() {
        return {
            getCodeText: '获取验证码',
            getCodeIsWaiting: false,
            form: {
                phone: '',
                code: '',
                verifyType: 0
            }
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#f5f6fa')
    },
    mounted() {
        if (this.$store.state.token !== '') {
            this.$router.push({name: 'index'})
        }
    },
    methods: {
        doLogin() {
            if (this.form.phone.length !== 11) {
                _Message.warning('请填写正确的手机号码')
                return false;
            }
            if (this.form.code.length !== 4) {
                _Message.warning('请输入4位验证码');
                return false;
            }
            this.$api.post('/auth/loginByPhone', this.form).then(res => {
                _Message.success('登录成功')

                // 保存数据到本地
                this.$store.commit("setUserData", res.data.userInfo)
                this.$store.commit("setToken", res.data.token)

                // 跳转至首页
                this.$router.push({name: 'index'})

            }).catch(err => {
                _Message.warning(err.msg)
            })
        },
        sendSMSCode() {
            if (this.form.phone.length !== 11) {
                _Message.warning('请填写正确的手机号码')
                return false;
            }
            this.getCodeText = "短信发送中..."
            this.getCodeIsWaiting = true

            this.$api.post('/auth/sendCode', {
                phone: this.form.phone,
                key: md5(this.form.phone + "baidu-list-server-sms-key")
            }).then(() => {
                // 这里此提示会被this.start()方法中的提示覆盖
                _Message.success('验证码已发送')
                // 通知验证码组件内部开始倒计时
                this.setTimer()
            }).catch(err => {
                _Message.warning(err.msg)
                this.getCodeIsWaiting = false;
                this.getCodeText = "获取验证码"
                clearInterval(this.Timer);
            })
        },
        setTimer() {
            let holdTime = 59,
                _this = this;
            _this.getCodeText = "重新获取(60)"
            _this.Timer = setInterval(() => {
                if (holdTime <= 0) {
                    _this.getCodeIsWaiting = false;
                    _this.getCodeBtnColor = "#ffffff";
                    _this.getCodeText = "获取验证码"
                    clearInterval(_this.Timer);
                    return;
                }
                _this.getCodeText = "重新获取(" + holdTime + ")"
                holdTime--;
            }, 1000)
        }
    }
}
</script>

<style>
body {
    background-color: #f5f6fa;
}
</style>
<style scoped>
.login-form {
    /*background: hsla(0, 0%, 100%, .8);*/
    min-width: 400px;
    width: 15%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -80%);
    padding: 20px;
}
</style>